import React from 'react';
import { Button } from '@material-ui/core';
import getConfig from 'next/config';
import { Experience } from '@/salesExperience/types';
import { WEB1, WEB2 } from '@/salesExperience/constants';
import { useCookie } from '../helpers/cookies';
import { SALES_EXPERIENCE, SEGNUM } from '../constants';

const isLocal = getConfig()?.publicRuntimeConfig.ENVIRONMENT_NAME === 'local';

const experienceValueMapper = {
  [WEB1]: '100',
  [WEB2]: '0'
};

type UpdateSalesExperienceButtonProps = {
  experience: Experience;
};
const UpdateSalesExperienceButton = ({ experience }: UpdateSalesExperienceButtonProps): JSX.Element => {
  const buttonText = experience.slice(3);
  const cookieName = isLocal ? SALES_EXPERIENCE : SEGNUM;
  const experienceValue = isLocal ? experience : experienceValueMapper[experience];
  const [, setValue] = useCookie(cookieName, '');

  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={() => { setValue(experienceValue); }}
    >
      Set {buttonText} Sales Experience
    </Button>
  );
};

export default UpdateSalesExperienceButton;
