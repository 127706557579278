import {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useStoreInfoQuery,
  OrderActions,
  CustomerActions
} from '@pizza-hut-us-development/client-core';

import { localizationSelectors } from '@/localization/localizationSelectors';
import { OccasionApi } from '@/localization/constants';

import standaloneApiClient from '@/api/standaloneApiClient';
import StandaloneEndpoints from '@/api/standaloneApiClient/endpoints';

import { useCreateCart } from '@/clientCore/cart/hooks/useCreateCart';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { useGetCart } from '@/clientCore/cart/hooks/useGetCart';
import { CartError } from '@/clientCore/cart/hooks/types';

import { CART_CC_ID } from '@/configuration/constants';
import { INVALID_CART_ID_API_RESPONSE_CODE } from '@/checkout/CheckoutPage/constants';

export const useCCLocalization = () => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const prevLocalizationToken = useRef('');

  const dispatch = useDispatch();
  const [createCart] = useCreateCart();
  const [{ isError, error }] = useGetCart();

  // Grab our localized store details
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const occasion = useSelector(localizationSelectors.occasion);
  const localizedAddress = useSelector(localizationSelectors.currentlyLocalizedAddress);
  const ccStore = useSelector(orderSelectors.store);

  // We need to grab storeInfo from this hook, because CC uses v2 endpoint, and our current flows use v1
  // && The responses are vastly different between v1 and v2
  const { data: storeInfo } = useStoreInfoQuery(storeDetails?.storeNumber, { skip: storeDetails?.storeNumber === undefined });

  const loadSavedCartId = useCallback(async () => {
    setIsInitialLoad(false);

    const getCartIdInCookie = async () => {
      const { cookieValue } = await standaloneApiClient.post(
        { cookieName: CART_CC_ID },
        StandaloneEndpoints.GET_COOKIE_VALUE
      );
      return cookieValue;
    };

    const cartCookieId = await getCartIdInCookie();
    if (cartCookieId) {
      // setting cartId will cause useGetCart to fetch existing cart
      dispatch(OrderActions.setCartId(cartCookieId));
      return true;
    }

    return false;
  }, [dispatch]);

  const loadOrCreateCart = useCallback(async () => {
    const hasSavedCartId = isInitialLoad && (await loadSavedCartId());
    if (hasSavedCartId) {
      return;
    }
    createCart({ shouldResetYumCart: true });
  }, [createCart, isInitialLoad, loadSavedCartId]);

  useEffect(() => {
    // recreate cart if cart is expired
    if (isError && (error as CartError)?.data?.errorCode === INVALID_CART_ID_API_RESPONSE_CODE) {
      createCart();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, error]);

  useEffect(() => {
    const hasValidToken = ccStore?.token && ccStore.token === storeDetails?.storeToken && ccStore.token !== prevLocalizationToken.current;
    if (!hasValidToken) {
      return;
    }

    prevLocalizationToken.current = ccStore.token ?? '';
    loadOrCreateCart();
  }, [storeDetails, ccStore, loadOrCreateCart]);

  useEffect(() => {
    if (storeInfo && storeDetails?.storeNumber && occasion) {
      // We also have to set our search address in CC
      const searchAddress = OccasionApi[occasion] === 'DELIVERY' && localizedAddress
        ? {
          occasionId: OccasionApi.D,
          address1: localizedAddress.address,
          address2: localizedAddress.address2,
          city: localizedAddress.city,
          state: localizedAddress.state,
          postalCode: localizedAddress.zipcode,
          geocodeValidate: false,
          limit: 5
        }
        : {
          occasionId: OccasionApi.C,
          postalCode: storeDetails.zipcode,
          geocodeValidate: false,
          limit: 5
        };
      dispatch(CustomerActions.setSearchAddress(searchAddress));
      const store = {
        ...storeInfo,
        token: storeDetails.storeToken ?? ''
      };
      dispatch(OrderActions.setStore(store));
    }
  }, [dispatch, localizedAddress, storeDetails, storeInfo, occasion]);
};
