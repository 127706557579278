/* eslint-disable max-len */
import React from 'react';
import RemoveLocalizationButton from './configButtons/RemoveLocalizationButton';
import { WEB1, WEB2 } from '@/salesExperience/constants';
import UpdateSalesExperienceButton from './configButtons/UpdateSalesExperience';
import {
  CMS_ENV_ID,
  GQL_URL,
  GQL_VERSION,
  DISABLE_GQL_SSR,
  DISABLE_GQL_CLIENT_SIDE_DECORATION,
  ENABLE_GOOGLE_TAG_MANAGER,
  SHOULD_MOCK_CLIENTS,
  CHECKOUT_FORM_TIME_OUT,
  SALES_EXPERIENCE,
  UATOKEN_EXPIRATION_TIME_COOKIE_NAME,
  ADVANCED_CART_DIAGNOSTICS_COOKIE_NAME,
  GUEST_TOKEN_REFRESH_INTERVAL_COOKIE_NAME
} from './constants';
import ThrowErrorButton from './configButtons/ThrowErrorButton';

export type Config = {
  title: string;
  cookieName: string;
  description?: string;
  configName?: string;
  isToggle?: boolean;
  defaultValue?: string;
  autocomplete?: boolean;
  toggleValues?: string[];
  buttonComponent?: JSX.Element;
  overrideAlert?: boolean;
  options?: {
    label: string;
    value: string;
  }[];
};

type ConfigOptions = {
  content: Config[];
  featureFlags: Config[];
  development: Config[];
  diagnostics: Config[];
};

const contentConfigs: Config[] = [
  {
    title: 'CMS environment ID',
    description: 'Modifies the draft used for menu and content data.',
    configName: 'CMS_ENVIRONMENT_ID',
    cookieName: CMS_ENV_ID,
    overrideAlert: true,
    autocomplete: true,
    options: [
      { label: 'nov8release', value: 'nov8release' },
      { label: 'aug2bdb', value: 'aug2bdb' },
      { label: 'aug30detroit', value: 'aug30detroit' },
      { label: 'jun8edge', value: 'jun8edge' },
      { label: 'july26obp', value: 'july26obp' },
      { label: 'jun14carryoutpairs', value: 'jun14carryoutpairs' },
      { label: 'jan3meltsttb2', value: 'jan3meltsttb2' },
      { label: 'march14phase3bny', value: 'march14phase3bny' }
    ]
  },
  {
    title: 'GQL URL',
    description: 'Modifies the GQL URL being used.',
    configName: 'GQL_URL',
    cookieName: GQL_URL,
    overrideAlert: true,
    options: [
      {
        label: 'Consolidated GQL Dev',
        value:
          'https://services-dev.digital.pizzahut.com/consolidated-gql/query?apikey=CAxPIsnVsf7SJPbAy8T8tCXo688LM5fu'
      },
      {
        label: 'Consolidated GQL STAGING',
        value:
          'https://services-staging.digital.pizzahut.com/consolidated-gql/query?apikey=CAxPIsnVsf7SJPbAy8T8tCXo688LM5fu'
      },
      {
        label: 'Consolidated GQL PRODUCTION',
        value:
          'https://services.digital.pizzahut.com/consolidated-gql/query?apikey=zrUXk5Ri1F9RKqNLKh5ie3CPGTBifOD5'
      }
    ]
  },
  {
    title: 'GQL Version',
    description: 'Modifies the GQL schema version. V2 is deprecated.',
    configName: 'GQL_VERSION',
    cookieName: GQL_VERSION,
    overrideAlert: true,
    options: [
      { label: 'V3', value: 'v3' },
      { label: 'V2', value: 'v2' }
    ]
  }
];

const featureFlagConfigs: Config[] = [
  {
    title: 'Advanced Cart Diagnostics',
    cookieName: ADVANCED_CART_DIAGNOSTICS_COOKIE_NAME,
    overrideAlert: true,
    isToggle: true,
    defaultValue: 'false',
    toggleValues: ['false', 'true']
  },
  {
    title: 'GraphQL server-side caching?',
    description:
      'Disables GQL server-side caching so the query network request can appear client-side.',
    cookieName: DISABLE_GQL_SSR,
    overrideAlert: true,
    isToggle: true,
    defaultValue: 'true',
    toggleValues: ['false', 'true']
  },
  {
    title: 'GraphQL client-side decoration?',
    description:
      'Today, the @client GQL directive is used to "hardcode" certain fields in certain GQL queries. Meaning the data is not coming from GQL. This toggle allows you to disable that, so you can test these features with real or mocked GQL calls.',
    cookieName: DISABLE_GQL_CLIENT_SIDE_DECORATION,
    overrideAlert: true,
    isToggle: true,
    defaultValue: 'true',
    toggleValues: ['false', 'true']
  },
  {
    title: 'Google Tag Manager',
    configName: 'ENABLE_GOOGLE_TAG_MANAGER',
    cookieName: ENABLE_GOOGLE_TAG_MANAGER,
    overrideAlert: true,
    isToggle: true,
    toggleValues: ['false', 'true']
  }
];

const developmentConfigs: Config[] = [
  {
    title: 'Mock API response?',
    description:
      'In order to make this work you need to use this mock strategy: https://pizzahut.atlassian.net/wiki/spaces/DI/pages/2981101611/Mock+Strategy',
    configName: 'ENABLE_MOCK_SERVER',
    cookieName: SHOULD_MOCK_CLIENTS,
    overrideAlert: true,
    isToggle: true,
    toggleValues: ['false', 'true']
  },
  {
    title: 'Time to clear checkout form (seconds)',
    description: 'Updates the time it takes for a checkout session to expire.',
    cookieName: CHECKOUT_FORM_TIME_OUT,
    overrideAlert: true,
    defaultValue: '900000',
    options: [
      { label: '900000', value: '900000' },
      { label: '600', value: '600' },
      { label: '60', value: '60' },
      { label: '30', value: '30' },
      { label: '15', value: '15' }
    ]
  },
  {
    title: 'UAToken Expiration Time',
    description:
      'Updates the time it takes for an auth token to expire in minutes. (Default: 10min)',
    cookieName: UATOKEN_EXPIRATION_TIME_COOKIE_NAME,
    overrideAlert: true,
    defaultValue: '10',
    options: [
      { label: '600', value: '600' },
      { label: '60', value: '60' },
      { label: '30', value: '30' },
      { label: '15', value: '15' },
      { label: '10', value: '10' }
    ]
  },
  {
    title: 'Guest Token Expiration Time',
    description: 'Updates the time it takes for a guest auth token to expire in minutes. (Default: 30min)',
    cookieName: GUEST_TOKEN_REFRESH_INTERVAL_COOKIE_NAME,
    overrideAlert: true,
    defaultValue: '30',
    options: [
      { label: '30', value: '30' },
      { label: '10', value: '10' },
      { label: '5', value: '5' },
      { label: '2', value: '2' },
      { label: '1', value: '1' },
      { label: '.25', value: '.25' }
    ]
  },
  {
    title: 'Localization',
    cookieName: '',
    buttonComponent: <RemoveLocalizationButton />
  },
  {
    title: 'Web1 Sales Experience',
    cookieName: SALES_EXPERIENCE,
    buttonComponent: <UpdateSalesExperienceButton experience={WEB1} />
  },
  {
    title: 'Web2 Sales Experience',
    cookieName: 'Sales-Experience',
    buttonComponent: <UpdateSalesExperienceButton experience={WEB2} />
  },
  {
    title: 'Throw Error',
    cookieName: '',
    buttonComponent: <ThrowErrorButton />
  }
];

const diagnosticConfigs: Config[] = [];

const configOptions: ConfigOptions = {
  content: contentConfigs,
  featureFlags: featureFlagConfigs,
  development: developmentConfigs,
  diagnostics: diagnosticConfigs
};

export default configOptions;
