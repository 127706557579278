import { FallbackList } from './types';

// ops-web-3578-yum_auth_enabled_store
const yumAuthStoreFallback: FallbackList = {
  yum_auth_enabled_store: [
    '317022'
  ]
};

export default yumAuthStoreFallback;
