import { FallbackList } from './types';

// ops-web-2029-cc_yum_enabled_store
const yumStoreFallback: FallbackList = {
  cc_yum_enabled_store: [
    '311016', '317022', '744107', '744110', '744134', '744186', '744140', '040467', '037219', '040468', '040469', '040470',
    '040471', '037220', '037221', '037222', '037230', '037233', '037238', '040787', '041414', '037232', '037235', '039573',
    '040772', '041085', '037223', '037224', '037229', '037234', '037236', '038988', '037225', '037227', '037237', '037239',
    '037240', '038993'
  ]
};

export default yumStoreFallback;
