import setAttributes from './setAttributes';
import { OPTIMIZELY_WEB_API_STORE } from '../constants';
import logger from '@/common/logger';
import apiFallback from './apiFallback';
import { getOrInitializeOptimizely } from '../optimizely';

export const callOptimizelyStoreApi = async (store_id: string, projectId :string) => {
  // Consider our query failed, and cancel if it takes longer than 4s
  const controller = new AbortController();
  const queryTimeout = setTimeout(() => {
    logger.error(new Error('Optimizely TAPI timeout'));
    controller.abort();
  }, 4_000);

  const apiToFetch = `${OPTIMIZELY_WEB_API_STORE + projectId}?project=${projectId}&j_storeid=${store_id}`;
  await fetch(apiToFetch, { signal: controller.signal })
    .then((response) => {
      clearTimeout(queryTimeout);
      return response.json();
    })
    .then((data) => {
      // Loop through all list attributes returned from optimizely api and
      // setAttributes in feature experiment in optimizely with same key.
      Object.keys(data?.lists)?.forEach((attributeKey) => {
        setAttributes({ [attributeKey]: data.lists[attributeKey] });
      });
    }).catch((err) => {
      const optimizely = getOrInitializeOptimizely();
      const fallbackEnabled = optimizely?.isFeatureEnabled('fr-web-3578-optimizely_api_fallback');
      if (fallbackEnabled) {
        // If the TAPI fails, fallback to local data and use that to set attributes
        const fallbackData = apiFallback(store_id);

        Object.keys(fallbackData).forEach((attributeKey) => {
          setAttributes({ [attributeKey]: fallbackData[attributeKey] });
        });
      }

      clearTimeout(queryTimeout);
      logger.error(err);
    });
};
