import yumAuthStoreFallback from './fallback.yumAuthStores';
import yumStoreFallback from './fallback.yumStores';
import { FallbackList } from './types';

const apiFallback = (storeNumber: string): Record<string, boolean> => {
  const fallbackList: FallbackList = {
    ...yumAuthStoreFallback,
    ...yumStoreFallback
  };

  const decisionObject: Record<string, boolean> = {};

  Object.keys(fallbackList).forEach((key) => {
    const listKey = key as keyof FallbackList;
    if (fallbackList[listKey]?.includes(storeNumber)) {
      decisionObject[listKey] = true;
    } else {
      decisionObject[listKey] = false;
    }
  });

  return decisionObject;
};

export default apiFallback;
