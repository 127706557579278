import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Cart, OrderActions, useGetCartQuery
} from '@pizza-hut-us-development/client-core';

import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { toggleCartLoadingStatus } from '@/clientCore/redux/cart/CartSlice';
import { CartAlert } from '@/domain/cart/types';
import {
  addSortedItemPointer,
  clearSortedItemPointers,
  setCartChangedAlert
} from '@/clientCore/redux/rail/CartRailSlice';
import { presentationalRailSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/rail/presentationalRailSelectors';
import { calculateCartQuantity } from '@/clientCore/cart/helpers';

export const useGetCart = () => {
  const dispatch = useDispatch();

  const cartId = useSelector(orderSelectors.cartId);
  const currentCart = useSelector(orderSelectors.cart);
  const sortedItemPointers = useSelector(presentationalRailSelectors.sortedItemPointers);

  const [forceRefetch, setForceRefetch] = useState(false);

  /**
   * Currently we only want to refetch a cart:
   * - On page load when we have an existing cart ID stored in a cookie.
   * - When navigating to checkout page to check the state of the cart before checking out.
   *
   * This prevents extra queries to GET /carts if there is already a cart in the client core redux state,
   * in addition to skipping if we don't have a cartId. This is required because the RTKQ GET /carts
   * query is invalidated after every cart mutation which causes this query to refetch.
   *
   * Cart state updates already happen after every mutation internally within client core,
   * so we don't need to manage this ourselves here.
   *
   * Consumers can call getCart to force the query to refetch the data
   * e.g app/clientCore/checkout/hooks/useCheckout.ts
   */
  const skip = useMemo(() => !cartId || (!!currentCart && !forceRefetch), [cartId, currentCart, forceRefetch]);

  const {
    data,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    isUninitialized,
    error,
    refetch
  } = useGetCartQuery(cartId, { skip });

  useEffect(() => {
    if (!cartId) dispatch(clearSortedItemPointers());
  }, [dispatch, cartId]);

  const dispatchAlertIfCartChanged = useCallback((updatedCart: Cart) => {
    if (!currentCart || !updatedCart) {
      return;
    }

    const orderItemQuantityChanged = calculateCartQuantity(currentCart.items) !== calculateCartQuantity(updatedCart.items);
    const orderTotalPriceChanged = currentCart.total !== updatedCart.total;

    if (orderItemQuantityChanged || orderTotalPriceChanged) {
      const cartAlert: CartAlert = {
        displayAlert: true,
        quantityChanged: orderItemQuantityChanged,
        previousPrice: currentCart.total,
        currentPrice: updatedCart.total,
        itemsRemoved: [] // TODO: where does this come from
      };
      dispatch(setCartChangedAlert(cartAlert));
    }
  }, [currentCart, dispatch]);

  useEffect(() => {
    if (!isSuccess || !data) {
      return;
    }

    setForceRefetch(false);
    dispatch(OrderActions.setCart(data));
    dispatchAlertIfCartChanged(data);

    if (!sortedItemPointers.length && data.items.length) {
      // store initial sort order for existing cart
      data.items.forEach((item) => dispatch(addSortedItemPointer({ cartItemId: item.cartItemId, itemId: item.id })));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isSuccess, data]);

  useEffect(() => {
    const isGetCartLoadingOrFetching = isLoading || isFetching;
    dispatch(
      toggleCartLoadingStatus({ loadingState: isGetCartLoadingOrFetching })
    );
  }, [dispatch, isLoading, isFetching]);

  const getCart = useCallback(async () => {
    if (isUninitialized) {
      // can't refetch a query if it's not been started
      setForceRefetch(true);
      return;
    }

    try {
      const result = await refetch();
      if (!result.data) {
        return;
      }

      dispatchAlertIfCartChanged(result.data);
    } catch (e) {
      console.error(e);
    }
  }, [dispatchAlertIfCartChanged, isUninitialized, refetch]);

  return [{
    data,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    isUninitialized,
    error,
    hasCartId: cartId
  }, { getCart }];
};
